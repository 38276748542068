exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cases-index-tsx": () => import("./../../../src/pages/cases/index.tsx" /* webpackChunkName: "component---src-pages-cases-index-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-link-tsx": () => import("./../../../src/pages/link.tsx" /* webpackChunkName: "component---src-pages-link-tsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-pro-tsx": () => import("./../../../src/pages/pro.tsx" /* webpackChunkName: "component---src-pages-pro-tsx" */),
  "component---src-pages-protected-jsx": () => import("./../../../src/pages/protected.jsx" /* webpackChunkName: "component---src-pages-protected-jsx" */),
  "component---src-pages-schools-jsx": () => import("./../../../src/pages/schools.jsx" /* webpackChunkName: "component---src-pages-schools-jsx" */),
  "component---src-pages-schools-onboarding-tsx": () => import("./../../../src/pages/schools/onboarding.tsx" /* webpackChunkName: "component---src-pages-schools-onboarding-tsx" */),
  "component---src-pages-somebuddy-index-jsx": () => import("./../../../src/pages/somebuddy/index.jsx" /* webpackChunkName: "component---src-pages-somebuddy-index-jsx" */),
  "component---src-pages-summit-live-streaming-tsx": () => import("./../../../src/pages/summit-live-streaming.tsx" /* webpackChunkName: "component---src-pages-summit-live-streaming-tsx" */),
  "component---src-pages-sustainable-development-goals-jsx": () => import("./../../../src/pages/sustainable-development-goals.jsx" /* webpackChunkName: "component---src-pages-sustainable-development-goals-jsx" */),
  "component---src-templates-blog-index-template-jsx": () => import("./../../../src/templates/blogIndexTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-index-template-jsx" */),
  "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2020-2020-10-20-index-en-us-md": () => import("./../../../src/templates/blogTemplate.jsx?__contentFilePath=/home/circleci/application/src/markdown-pages/blog/2020/2020-10-20/index.en-us.md" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2020-2020-10-20-index-en-us-md" */),
  "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2021-2021-10-11-index-en-us-mdx": () => import("./../../../src/templates/blogTemplate.jsx?__contentFilePath=/home/circleci/application/src/markdown-pages/blog/2021/2021-10-11/index.en-us.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2021-2021-10-11-index-en-us-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2022-2022-03-29-index-en-us-mdx": () => import("./../../../src/templates/blogTemplate.jsx?__contentFilePath=/home/circleci/application/src/markdown-pages/blog/2022/2022-03-29/index.en-us.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2022-2022-03-29-index-en-us-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2022-2022-08-11-index-en-us-mdx": () => import("./../../../src/templates/blogTemplate.jsx?__contentFilePath=/home/circleci/application/src/markdown-pages/blog/2022/2022-08-11/index.en-us.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-2022-2022-08-11-index-en-us-mdx" */),
  "component---src-templates-case-template-jsx": () => import("./../../../src/templates/caseTemplate.jsx" /* webpackChunkName: "component---src-templates-case-template-jsx" */),
  "component---src-templates-legal-template-jsx": () => import("./../../../src/templates/legalTemplate.jsx" /* webpackChunkName: "component---src-templates-legal-template-jsx" */),
  "component---src-templates-testimonial-template-jsx": () => import("./../../../src/templates/testimonialTemplate.jsx" /* webpackChunkName: "component---src-templates-testimonial-template-jsx" */)
}

